<template>
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
            <mini-statistics-card 
                title="Usuarios registrados" 
                :value="metrics.total_registered" 
                :percentage="{
                    value: '+505%',
                    color: 'text-success',
                }" 
                :icon="{
                    component: 'ni ni-money-coins',
                    background: 'bg-gradient-primary',
                }" 
                direction-reverse 
            />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
            <mini-statistics-card 
                title="Asistentes totales" 
                :value="metrics.total_users"  
                :percentage="{
                     value: '+3%',
                    color: 'text-success',
                }" 
                :icon="{
                    component: ' ni ni-world',
                    background: 'bg-gradient-primary',
                }" 
                direction-reverse />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
            <mini-statistics-card 
                title="Porcentaje de asistencia" 
                :value="Math.round((metrics.total_users * 100 / metrics.total_registered ))+'%'" 
                :percentage="{
                    value: '-2%',
                    color: 'text-danger',
                }" 
                :icon="{
                    component: 'ni ni-paper-diploma',
                    background: 'bg-gradient-primary',
                }" 
                direction-reverse 
            />
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0">
            <mini-statistics-card 
                title="Permanencia promedio" 
                :value="metrics.prom_minutes+'min'" 
                :percentage="{
                    value: '+5%',
                    color: 'text-success',
                }" 
                :icon="{
                    component: 'ni ni-cart',
                    background: 'bg-gradient-primary',
                }" 
                direction-reverse 
            />
        </div>
    </div>
</template>
<script>
import { fetchWrapper } from '@/helpers';
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

export default {
    name: "asistencia",
    data() {
        return {
            iconBackground: "bg-gradient-primary",
            metrics: {
                total_minutes: 0,
                total_users: 0,
                prom_minutes: 0,
                total_registered: 0
            }
        };
    },
    components: {
        MiniStatisticsCard
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {

            let from = "2022-11-06"
            let to = "2022-11-12";
            const baseUrl = process.env.VUE_APP_BASE_URL;
            const id_contract = process.env.VUE_APP_ID_CONTRACT;
            const url = `${baseUrl}/v1/enter_leaves_metrics/count_minutes_between_dates/${id_contract}/${from}/${to}`;

            console.log(url);
            // enviar con axios esta solicitud, si es ok, lo envía a login
            fetchWrapper.get(url)
                .then(result => this.metrics = result)
                .catch(error => console.log(error))
        }
    }
};
</script>