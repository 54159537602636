<template>
    <div class="card z-index-2">

        <!-- <gradient-line-chart 
            id="chart-line" 
            title="Asistentes" 
            :chart="chart"
        /> -->

        <div class="card h-100">
            <div class="pb-0 card-header">
                <h6 class="">Visitas por espacio</h6>
                <!-- <p class="text-sm"><i class="fa fa-arrow-up text-success" aria-hidden="true"></i>
                    <span class="font-weight-bold">24%</span> this month
                </p> -->
            </div>
            <div class="p-3 card-body">
                <div class="timeline timeline-one-side" data-timeline-axis-style="dotted">
                    <div v-for="trace in metrics" :key="trace.date" class="mb-3 timeline-block"><span class="timeline-step"><i
                                class="ni text-gradient ni-check-bold text-primary"></i></span>
                        <div class="timeline-content">
                            <h6 class="mb-0 text-sm font-weight-bold text-dark text-capitalize">
                                {{trace.trace}}
                            </h6>
                            <p class="mt-1 mb-0 text-xs text-secondary font-weight-bold">
                                {{trace.count}} visitas
                            </p>
                            <!---->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {{ chart }}
</template>
<script>
import { fetchWrapper } from '@/helpers';
//import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";

export default {
    name: "asistencia",
    data() {
        return {
            iconBackground: "bg-gradient-primary",
            metrics : [],
            action: 'enter',
            trace: 'all'
            /* nodes_names: {
                node1:'Ingreso del loby',
                node2:'Loby',
                node3:'Stand Konecta',
                node4:'Stand Upbe',
                node5:'Stand Apex',
                node6:'Stand Verint',
                node7:'Stand uContact',
                node8:'Stand Atento',
                node9:'Auditorio',
                node10:'Auditorio',
            } */
        };
    },
    components: {
        //GradientLineChart
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {

            let from = "2022-11-06"
            let to = "2022-11-12";
            const baseUrl = process.env.VUE_APP_BASE_URL;
            const id_contract = process.env.VUE_APP_ID_CONTRACT;
            const url = `${baseUrl}/v1/action_metrics/counts_between_dates/${id_contract}/${from}/${to}/${this.action}/${this.trace}`;

            // enviar con axios esta solicitud, si es ok, lo envía a login
            fetchWrapper.get(url)
                .then(result => {
                    this.metrics = result;
                })
                .catch(error => console.log(error))
        }
    }
};
</script>