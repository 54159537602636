<template>
  <div class="py-4 container-fluid">
    <!-- Asistencia -->
    <Asistencia />
    <!--<div class="row">
      
       <div class="col-lg-7">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="
              background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">GreenAr</h5>
                <p class="text-white mb-5">
                  Wealth creation is an evolutionarily recent positive-sum game.
                  It is all about who take the opportunity first.
                </p>
                <a
                  class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  href="javascript:;"
                >
                  Ir a la landing
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>-->
    <div class="mt-4 row">

      <div class="mb-4 col-lg-6 mb-lg-0">
        <div class="card z-index-2 mb-3">
          <!-- panel info evento -->
          <PanelInfoEvento />


        </div>
        <div class="card z-index-2 mb-3">
          <div class="card z-index-2">
            <div class="pb-0 card-header pb-2">

              <h6 class="">Interacciones por espacio</h6>
            </div>
            <div class="row  card-body">
              <div class="col-4">
                <button v-for="farma, index in farmas_name" :key="index"
                  class="btn mb-0 bg-outline-secondary btn-sm mb-3 w-100"
                  :class='{ "bg-gradient-primary": trace == farma }' v-on:click="changeFarma(farma)">
                  {{ farma }}
                </button><br>
              </div>
              <div class="col-8">
                <div class="timeline timeline-one-side" data-timeline-axis-style="dotted">
                  <div v-for="trace in farma_metrics" :key="trace.date" class="mb-3 timeline-block"><span
                      class="timeline-step"><i class="ni text-gradient ni-check-bold text-danger"></i></span>
                    <div class="timeline-content">
                      <h6 class="mb-0 text-sm font-weight-bold text-dark text-capitalize">
                        {{ trace.action }}
                      </h6>
                      <p class="mt-1 mb-0 text-xs text-secondary font-weight-bold">
                        {{ trace.count }} visitas
                      </p>
                      <!---->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card z-index-2">
          <div class="p-3 card-body"> -->
        <!-- <reports-bar-chart
              id="chart-bar"
              title="active Users"
              description="(<strong>+23%</strong>) than last week" -->
        <!-- <reports-bar-chart id="chart-bar" :chart="{
              labels: [
                'Stand 1',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              datasets: {
                label: 'Sales',
                data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
              },
              }" :items="[
              {
                icon: {
                  color: 'primary',
                  component: faUsers,
                },
                label: 'users',
                progress: { content: '7K', percentage: 60 },
              },
              {
                icon: { color: 'info', component: faHandPointer },
                label: 'clicks',
                progress: { content: '1.3m', percentage: 90 },
              },
              {
                icon: { color: 'warning', component: faCreditCard },
                label: 'Visitantes',
                progress: { content: '1k', percentage: 30 },
              },
              {
                icon: { color: 'danger', component: faScrewdriverWrench },
                label: 'Stands',
                progress: { content: '9', percentage: 50 },
              },
            ]" />
          </div>


        </div> -->



      </div>

      <div class="mb-4 col-lg-3 mb-lg-0">
        <!-- line chart -->
        <GraficoDias />
      </div>

      <div class="mb-4 col-lg-3 mb-lg-0">
        <!-- line chart -->
        <TracesDias />
      </div>

      <div class="col-lg-3">

      </div>


    </div>

    <!-- <div class="row my-4">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <projects-card />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Orders overview"
          description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month"
        >
          <timeline-item
            color="primary"
            icon="bell-55"
            title="$2400 Design changes"
            date-time="22 DEC 7:20 PM"
          />
          <TimelineItem
            color="danger"
            icon="html5"
            title="New order #1832412"
            date-time="21 DEC 11 PM"
          />
          <TimelineItem
            color="info"
            icon="cart"
            title="Server payments for April"
            date-time="21 DEC 9:34 PM"
          />
          <TimelineItem
            color="warning"
            icon="credit-card"
            title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
          />
          <TimelineItem
            color="primary"
            icon="key-25"
            title="Unlock packages for development"
            date-time="18 DEC 4:54 AM"
          />
          <TimelineItem
            color="info"
            icon="check-bold"
            title="Notifications unread"
            date-time="15 DEC"
          />
        </timeline-list>
      </div>
    </div> -->
  </div>
</template>
<script>

import { fetchWrapper } from '@/helpers';

//import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
//import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";

import PanelInfoEvento from "@/components/dashboard/PanelInfoEvento.vue";
import Asistencia from "@/components/dashboard/Asistencia.vue";
import GraficoDias from "@/components/dashboard/GraficoDias.vue";
import TracesDias from "@/components/dashboard/TracesDias.vue";


import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "dashboard-default",
  data() {
    return {
      iconBackground: "bg-gradient-primary",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      farmas_name: ['staf', 'farma 1', 'farma 2', 'farma 3 Coratio', 'farma 3 Kardias', 'farma 4', 'farma 5', 'farma 6', 'farma 7', 'nutri', 'odonto'],

      action: 'all',
      trace: 'farma 1',

      farma_metrics: [],

      chart: {
        labels: [
          'cargando info'
        ],
        datasets: {
          label: 'Interacciones',
          data: [0],
        },
      },

      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    //MiniStatisticsCard,
    //ReportsBarChart,
    PanelInfoEvento,
    Asistencia,
    GraficoDias,
    TracesDias
    // ProjectsCard,
    //     TimelineList,
    // TimelineItem,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {

      let from = "2022-11-06"
      let to = "2022-11-12";
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const id_contract = process.env.VUE_APP_ID_CONTRACT;
      const url = `${baseUrl}/v1/action_metrics/counts_between_dates/${id_contract}/${from}/${to}/${this.action}/${this.trace}`;

      // enviar con axios esta solicitud, si es ok, lo envía a login
      fetchWrapper.get(url)
        .then(result => {
          console.log('getData', result);

          this.farma_metrics = result

        })
        .catch(error => console.log(error))
    },

    changeFarma(farma) {
      this.trace = farma;
      this.getData();
    }
  },

};
</script>
