<template>
    <h1>Page only Authenticate Midleware</h1>
    <h3> User: {{ user}} </h3>
    <button @click="logOut">Log Out</button>
</template>
<script>
import store from "../../store";
import { mapActions } from "vuex";
export default {
    name: "OnlyAuthPage",
    data(){
        return {
            user: store.state.user_login_auth
        }
    },
    methods:{
        ...mapActions(["setAuthUser"]),
        logOut(){
            this.setAuthUser(null);
            this.$router.push("/tests/loginauth")
        }
    }
}
</script>