<template>
    <div class="card" style="max-heigt:700px">
        <div class="card-header pb-0">
            <div class="row">
                <h6>Personas que que estuvieron presentes en el evento <span style="font-size:10px">({{ metrics.length
                }})</span></h6>
                <input type="text" placeholder="Buscar usuario" class="form-control pe-3" v-model="search_user">
            </div>
        </div>
        <div class="card-body px-0">
            <div class="table-responsive">
                <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Usuario
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Farma
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Línea
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                Permanencia estimada
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in metrics" :key="user.id">
                            <td>
                                <div class="d-flex px-2 py-1">
                                    <div>
                                        <div class="avatar avatar-sm me-3">
                                            <img src="https://cdn-icons-png.flaticon.com/512/6073/6073874.png">
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                                        <p class="text-xs">{{ user.email }}</p>
                                    </div>
                                </div>
                            </td>
                            <td class="align-middle text-sm">
                                <span class="text-xs">{{ farmas_name[user.aux_1] }}</span>
                            </td>
                            <td class="align-middle text-sm">
                                <span class="text-xs">{{ user.aux_4 }}</span>
                            </td>
                            <td class="align-middle text-center text-sm">
                                <span class="text-xs font-weight-bold">{{ user.total_minutes }} minutos</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
import { fetchWrapper } from '@/helpers';
//import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";

export default {
    name: "Asistentes",
    data() {
        return {
            iconBackground: "bg-gradient-primary",
            metrics: [],
            farmas_name: ['Staf', 'Farma 1', 'Farma 2', 'Farma 3 Coratio', 'Farma 3 Kardias', 'Farma 4', 'Farma 5', 'Farma 6', 'Farma 7', 'Nutri', 'Odonto'],
            search_user: '',
        };
    },
    components: {
        //GradientLineChart
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {

            let from = "2022-11-06"
            let to = "2022-11-12";
            const baseUrl = process.env.VUE_APP_BASE_URL;
            const id_contract = process.env.VUE_APP_ID_CONTRACT;
            const url = `${baseUrl}/v1/enter_leaves_metrics/users_data_uniques_between_dates/${id_contract}/${from}/${to}`;

            // enviar con axios esta solicitud, si es ok, lo envía a login
            fetchWrapper.get(url)
                .then(result => {
                    this.metrics = result;
                })
                .catch(error => console.log(error))
        }
    },

    computed: {
        users() {
            return this.metrics.filter((element) => element.name.toLowerCase().includes(this.search_user.toLowerCase()));
        }
    }
};
</script>