<template>
  <div class="card mb-4">
    <Asistentes />
  </div>
</template>

<script>
import Asistentes from "@/components/dashboard/Asistentes.vue";
export default {
components: {
    Asistentes
  }
}
</script>