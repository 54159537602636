<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Tabla de usuarios</h6>
      <!-- <button class="btn">Cargar usuarios</button> -->
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Crear evento
      </button>

      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Crear evento</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addEvent">
                <div class="form-group">
                  <label for="descripcion">Descripcion</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    placeholder="Descripcion"
                    v-model.trim="description"
                    required
                  />
                </div>
                <label for="descripcion">Fecha del evento</label>
                <Datepicker v-model="date"></Datepicker>
                <div class="form-group mt-2">
                  <label for="numeroTelefono">Numero de auditorio</label>
                  <input
                    type="number"
                    class="form-control"
                    id="numeroTelefono"
                    placeholder="Numero de auditorio"
                    v-model.trim="room"
                    required
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="submit" 
                    class="btn btn-primary"
                    >Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="editModal"
        tabindex="2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editModal">Editar evento</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="descripcion">Descripcion</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    placeholder="Descripcion"
                    v-model="currentEvent.description"
                  />
                </div>
                <label for="descripcion">Fecha del evento</label>
                <Datepicker v-model="date"></Datepicker>
                <div class="form-group mt-2">
                  <label for="numeroTelefono">Numero de auditorio</label>
                  <input
                    type="number"
                    class="form-control"
                    id="numeroTelefono"
                    placeholder="Numero de auditorio"
                    v-model="currentEvent.room"
                  />
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button 
                    type="button" 
                    class="btn btn-primary"
                    v-on:click="guardarEvent"
                    >Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Descripcion
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Fecha
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Auditorio
              </th>

              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="event in dataEvents" 
              :key="event.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div></div>
                  <div class="d-flex flex-column justify-content-center">
                    <p class="text-xs text-secondary mb-0">{{event.description}}</p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{event.date}}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <!-- <vsud-badge color="primary" variant="gradient" size="sm"
                  >SI</vsud-badge
                > -->
                {{event.room}}
              </td>
              <!-- <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >Argentina</span
                >
              </td> -->
              <td class="align-middle text-center text-sm">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#editModal"
                  @click="editEvent(event)"
                >
                  Editar
                </button>
              </td>
              <td class="align-middle text-center text-sm">
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="deleteEvent(event)"
                >
                  Eliminar
                </button>
              </td>

              <td class="align-middle"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import VsudAvatar from "@/components/VsudAvatar.vue";
// import VsudBadge from "@/components/VsudBadge.vue";
import img1 from "../assets/img/team-2.jpg";
import img2 from "../assets/img/team-3.jpg";
import img3 from "../assets/img/team-4.jpg";
import img4 from "../assets/img/team-3.jpg";
import img5 from "../assets/img/team-2.jpg";
import img6 from "../assets/img/team-4.jpg";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import axios from 'axios'

export default {
  name: "authors-table",
  data: () => ({
    description: '',
    date: '',
    room: '',
    dataEvents: [],
    currentEvent: {
      description: '',
      date: '',
      room: '',
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
    }
  }),
  components: {
    // VsudAvatar,
    // VsudBadge,
    Datepicker,
  },
  mounted() {
    this.getEvent()
  },
  methods: {
    async addEvent() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }
        const event = {
          description: this.description,
          date: this.date,
          room: this.room
        }
        this.dataEvents.push(event)        
        this.description = '',
        this.date = '',
        this.room = ''
        await axios.post('http://localhost:5000/api/events', {event}, getToken)
          .then(response => {
            console.log(response.data)
            response.data})

      } catch (error){
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers);
      }
    },
    async getEvent() {
      try {
        const getToken = {
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          }
        }

        this.dataEvents = await axios.get('http://localhost:5000/api/events', getToken)
        .then(response => response.data.events)
      } catch (error) {
         console.log(error.response.data);  
         console.log(error.response.status);  
         console.log(error.response.headers); 
      }
    },
    editEvent(event) {
      this.currentEvent = {
        description: event.description,
        date: event.date,
        room: event.room,
        id: event.id,
      }
      console.log(event.id)
    },
    async guardarEvent() {
      axios({
          method: 'put',
          url: 'http://localhost:5000/api/events/' + this.currentEvent.id,
          data: {
              fieldsToUpdate: this.currentEvent
          },
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataEvents = this.dataEvents.map(event => {
          if (event.id === this.currentEvent.id) {
            return this.currentEvent
          } else {
            return event
          }
        })
      });
    },
    deleteEvent(event) {
      axios({
          method: 'delete',
          url: 'http://localhost:5000/api/events/' + event.id,
          headers: {
            Authorization: process.env.VUE_APP_WP_API_KEY,
            'Content-Type': 'application/json; charset=utf-8'
          },
      }).then(() => {
        this.dataEvents = this.dataEvents.filter(r => {
          if (r.id !== event.id) {
            return r
          }
        })
      });
    }
  }
};
</script>

<style>
.btn-cargar-user {
  border: none;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 10px;
  width: 125px;
  height: 40px;
}
</style>
