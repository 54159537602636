import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from '@/stores';

import Dashboard from "@/views/Dashboard.vue";
// import Tables from "@/views/Tables.vue";
// import Billing from "@/views/Billing.vue";
// import VirtualReality from "@/views/VirtualReality.vue";
// import Profile from "@/views/Profile.vue";
// import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
//import store from "../store";
//import Auspiciantes from "@/views/Auspiciantes.vue";
import Inscriptos from "@/views/Inscriptos.vue";
import Asistentes from "@/views/Asistentes.vue";
import Eventos from "@/views/Eventos.vue";
import Ajustes from "@/views/Ajustes.vue";
import Roles from "@/views/Roles.vue";

/// Tests
import OnlyAuthPage from /* webpackChunkName: "Auth" */ "@/views/tests/OnlyAuthPage.vue";
import LoginAuthPage from /* webpackChunkName: "Auth" */ "@/views/tests/LoginAuthPage.vue";

/// _Change this for proyect name"_

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/inscriptos",
    name: "Inscriptos",
    component: Inscriptos,
  },
  {
    path: "/asistentes",
    name: "Asistentes",
    component: Asistentes,
  },
  {
    path: "/eventos",
    name: "Eventos",
    component: Eventos,
  },
  {
    path: "/ajustes",
    name: "Ajustes",
    component: Ajustes,
  },
  {
    path: "/roles",
    name: "Roles",
    component: Roles,
  },
  {
    path: "/asistentes",
    name: "Asistentes",
    component: Asistentes,
  },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   component: Tables,
  // },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/virtual-reality",
  //   name: "Virtual Reality",
  //   component: VirtualReality,
  // },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   component: Profile,
  // },
  // {
  //   path: "/rtl-page",
  //   name: "Rtl",
  //   component: Rtl,
  // },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  /// Test
  {
    path: "/tests/onlyauthpage",
    name: "OnlyAuthPage",
    component: OnlyAuthPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/tests/loginauth",
    name: "LoginAuthPage",
    component: LoginAuthPage,
    meta: { requiresAuth: false },
  },

  /// Proyect Name
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

/* router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Need to login
    if (store.state.user_login_auth.isLoggedIn) 
    {
      if (to.fullPath === "/sign-in") 
      {
        next({
          path: "/dashboard",
        });
      } 
      else 
      {
        next();
      }
    } 
    else 
    {
      next({
        path: "/sign-in",
      });
    }
  } 
  else 
  {
    if (store.state.user_login_auth.isLoggedIn) 
    {
      if (to.fullPath === "/sign-in") 
      {
        next({
          path: "/dashboard",
        });
      } 
      else 
      {
        next();
      }
    } 
    else 
    {
      next();
    }
  }
}); */

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/sign-in','/change-password/'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  console.log("to.path: ", to.path);
  console.log("authRequired", authRequired);
  console.log("auth", auth.user);

  if (authRequired && !auth.user) {
      auth.returnUrl = to.fullPath;
      return '/sign-in';
  }
});

export default router;
