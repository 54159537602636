<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Usuarios registrados <span style="font-size:10px">({{ dataUsers.length }})</span></h6>
      <!-- <button class="btn">Cargar usuarios</button> -->
      <!-- Button trigger modal -->
      <div style="width:600px; display: flex; justify-content: end;">

      <div class="me-4" style="width:300px">
        <input type="text" placeholder="Buscar usuario" class="form-control pe-3" v-model="search_user">
      </div>
      
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addUserModal"
        v-on:click="message = ''">
        Cargar nuevo
      </button>
    </div>



      <!-- <div class="modal fade" id="editModal" tabindex="2" aria-labelledby="addUserModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addUserModalLabel">Editar usuario</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input type="text" class="form-control" id="nombre" placeholder="Nombre" v-model="currentUser.name" />
                </div>
                <div class="form-group">
                  <label for="apellido">Apellido</label>
                  <input type="text" class="form-control" id="apellido" placeholder="Apellido"
                    v-model="currentUser.lastName" />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Enter email" v-model="currentUser.email" />
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"
                    v-model="currentUser.password" />
                </div>
                <div class="form-group">
                  <label for="especialidad">Especialidad</label>
                  <input type="text" class="form-control" id="especialidad" placeholder="Especialidad"
                    v-model="currentUser.speciality" />
                </div>
                <div class="form-group">
                  <label for="institucion">Institucion</label>
                  <input type="text" class="form-control" id="institucion" placeholder="Institucion"
                    v-model="currentUser.institution" />
                </div>
                <div class="form-group">
                  <label for="pais">Pais</label>
                  <input type="text" class="form-control" id="pais" placeholder="Pais" v-model="currentUser.country" />
                </div>
                <div class="form-group">
                  <label for="numeroTelefono">Telefono</label>
                  <input type="number" class="form-control" id="numeroTelefono" placeholder="Numero de telefono"
                    v-model="currentUser.phone" />
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="submit" class="btn btn-primary" v-on:click="guardarUser">Guardar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-4">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Nombre
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Línea
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Cargo
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Email
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id_user">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <!-- <vsud-avatar
                      :img="img1"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    /> -->
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ user.name_user }}</h6>
                    <!-- <p class="text-xs text-secondary mb-0">
                      
                    </p> -->
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ farma_name[user.aux_1] }}</p>
                <!-- <p class="text-xs text-secondary mb-0">Organization</p> -->
              </td>
              <td class="align-middle text-center text-sm">
                <span class="text-secondary text-xs font-weight-bold">{{ user.aux_4 }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ user.email_user }}</span>
              </td>
              <td class="align-right">
                <button type="button" class="btn btn-info me-3" data-bs-toggle="modal" data-bs-target="#addUserModal"
                  @click="editUser(user)">
                  Editar
                </button>
                <button type="button" class="btn btn-secondary" @click="deleteUser(user.email_user)">
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="addUserModal" tabindex="2" aria-labelledby="addUserModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addUserModalLabel">
              {{ is_edit ? "Editar usuario" : "Registrar nuevo usuario" }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">


            <div class="alert alert-info text-white" role="alert" v-show="message != ''">
              <span class="alert-icon me-2"><i class="ni ni-like-2"></i></span>
              <span class="alert-text">{{ message }}</span>
            </div>



            <form @submit.prevent="addUser">
              <div class="form-group">
                <label for="nombre">Nombre</label>
                <input type="text" class="form-control" id="nombre" placeholder="Nombre" v-model.trim="user.name"
                  required />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Ingresa tu Email" v-model.trim="user.email" required />
              </div>
              <div class="form-group" v-if="is_edit">
                <!-- <label for="exampleInputPassword1">Contraseña</label> -->
                <input type="hidden" class="form-control" id="exampleInputPassword1" placeholder="Password"
                  v-model.trim="user.password" required />
              </div>
              <div class="form-group">
                <label for="aux_1">Farma</label>
                <select name="aux_1" id="aux_1" v-model.trim="user.aux_1" class="form-control" required>
                  <option v-for="farma, index in farma_name" :key="farma" :value="index">{{ farma }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="linea">Linea</label>
                <input type="text" class="form-control" id="linea" placeholder="linea" v-model.trim="user.aux_4"
                  required />
              </div>
              <div class="form-group">
                <label for="id_role">Rol</label>
                <select name="" id="" v-model.trim="user.id_role" class="form-control" required>
                  <option value="4">Estandar</option>
                  <option value="3">Auditor</option>
                  <option value="2">Administrador</option>
                </select>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  Cancelar
                </button>
                <button type="submit" class="btn btn-primary">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { fetchWrapper } from '@/helpers';

export default {
  name: "authors-table",
  data: () => ({
    dataUsers: [],
    dataUsersAux: [],

    search_user:'',

    user: {
      id_user: '',
      name: '',
      name_user: '',
      id_role: '',
      email: '',
      email_user: '',
      password: 'Abcd1234',
      id_contract: "2",
      aux_1: '',
      aux_4: ''
    },

    farma_name: ['Staf', 'Farma 1', 'Farma 2', 'Farma 3 Coratio', 'Farma 3 KARDIAS', 'Farma 4', 'Farma 5', 'Farma 6', 'Farma 7', 'Nutri', 'Odonto'],

    message: '',
    colorAlert: "info",

    is_edit: false,
  }),
  mounted() {
    this.getUsers()
  },
  methods: {

    async getUsers() {

      const baseUrl = process.env.VUE_APP_BASE_URL;
      const id_contract = process.env.VUE_APP_ID_CONTRACT;
      const url = `${baseUrl}/v1/accessroles/list_by_contract/${id_contract}`;

      console.log(url);
      // enviar con axios esta solicitud, si es ok, lo envía a login
      fetchWrapper.get(url)
        .then(result => {
          this.dataUsers = result
          this.dataUsersAux = result
          //console.log(result);
        })
        .catch(error => console.log(error))
    },

    async addUser() {

      this.message = "";
      this.color = "info";
      
      let payload;
      

      const baseUrl = process.env.VUE_APP_BASE_URL;
      let url;

      if (this.is_edit) 
      {
        url = `${baseUrl}/v1/user_update/${this.user.id_user}`;
        
        payload = {
          name: this.user.name,
          id_role: this.user.id_role,
          email: this.user.email,
          id_contract: this.user.id_contract,
          aux_1: this.user.aux_1,
          aux_4: this.user.aux_4
        };
      }
      else 
      {
        url = `${baseUrl}/v1/register`;
        
        payload = {
          name: this.user.name,
          id_role: this.user.id_role,
          email: this.user.email,
          password: this.user.password,
          id_contract: this.user.id_contract,
          aux_1: this.user.aux_1,
          aux_4: this.user.aux_4
        };

        this.is_edit = false;

      }

      console.log("payload", payload);


      await fetchWrapper.post(url, payload)
        .then(result => {

          console.log("addUser", result);
          //this.getUsers();
          this.user.name_user = this.user.name;
          this.user.email_user = this.user.email;
          this.dataUsers.unshift(this.user);

          this.user = {
            name: '',
            id_role: '',
            email: '',
            id_contract: "2",
            aux_1: '',
            aux_4: ''
          };
          this.message = result.message;
        })
        .catch(error => {
          console.log("Error addUser: ", error)
          this.message = "Se produjo un error, intentelo de nuevo, o contactese con el administrador.";
          this.color = "dark";
        })
    },


    editUser(user) {
      this.user = {
        id_user: user.id_user,
        name: user.name_user,
        id_role: user.id_role,
        email: user.email_user,
        id_contract: user.id_contract,
        aux_1: user.aux_1,
        aux_4: user.aux_4
      };

      this.is_edit = true;

      console.log("this.user", this.user)
      console.log("this.is_edit", this.is_edit)
    },

    /* async guardarUser() {
      axios({
        method: 'put',
        url: 'http://localhost:5000/api/users/' + this.currentUser.id,
        data: {
          fieldsToUpdate: this.currentUser
        },
        headers: {
          Authorization: process.env.VUE_APP_WP_API_KEY,
          'Content-Type': 'application/json; charset=utf-8'
        },
      }).then(() => {
        this.dataUsers = this.dataUsers.map(user => {
          if (user.id === this.currentUser.id) {
            return this.currentUser
          } else {
            return user
          }
        })
      });
    }, */

    deleteUser(email) {

      if (window.confirm("Seguro de eliminar a este usuario?")) {
        const baseUrl = process.env.VUE_APP_BASE_URL;
        const url = `${baseUrl}/v1/user-destroy/${email}`;

        console.log(url);
        // enviar con axios esta solicitud, si es ok, lo envía a login
        fetchWrapper.delete(url)
          .then(result => {

            //this.getUsers();
            console.log(result);

            this.dataUsers = this.dataUsers.filter((element) => element.email_user != email);
          })
          .catch(error => console.log(error))
      }
    }
  },

  computed:{
    users(){
      return this.dataUsersAux.filter((element) => element.name_user.toLowerCase().includes(this.search_user.toLowerCase()));
    }
  }

}
</script>

<style>
.btn-cargar-user {
  border: none;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 10px;
  width: 125px;
  height: 40px;
}
</style>
