<template>
  <div class="card">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-lg-6">
          <div class="d-flex flex-column h-100">
            <p class="mb-1 pt-2 text-bold">Evento en curso</p>
            <h5 class="font-weight-bolder">{{data_event.name}}</h5>
            <p class="mb-5">{{data_event.description}}</p>
            <a 
              class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" 
              :href="data_event.company_web_url"
              target="_blanck"
            >
              Ir al evento
              <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
          <div class="bg-gradient-primary border-radius-lg h-100">
            <img src="../../assets/img/shapes/waves-white.svg"
              class="position-absolute h-100 w-50 top-0 d-lg-block d-none" alt="waves" />
            <div class="position-relative d-flex align-items-center justify-content-center h-100 p-3">
              <img class="w-100 position-relative z-index-2 pt-4" 
                :src="data_event.company_logo_url"
                alt="rocket" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted } from 'vue';
  import { fetchWrapper } from '@/helpers';

  let baseUrl;
  if (process.env.NODE_ENV === "development") 
  {
    baseUrl = process.env.VUE_APP_BASE_URL_DEV;
  } 
  else 
  {
    baseUrl = process.env.VUE_APP_BASE_URL;
  }

  const data_event = ref({});  

  onMounted(async () => {

    const id_contract = process.env.VUE_APP_ID_CONTRACT;
    const url = `${baseUrl}/v1/contracts/${id_contract}`;
    console.log(url);
    // enviar con axios esta solicitud, si es ok, lo envía a login
    fetchWrapper.get(url)
        .then(result => data_event.value = result)
        .catch(error => console.log(error))
  });


</script>